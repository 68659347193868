.Cashless_Wallet {
    .cashlessCard {
        background-color: #fff;
        padding: 20px;
        border-radius: 16px;
        box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

        .differenceHold {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .insideLeft {
                .hTxt {
                    color: rgba(30, 10, 27, 0.6);
                    font-size: 18px;
                }

                .hNum {
                    color: #954386;
                    font-size: 40px;
                    font-weight: 600;
                }
            }

            .insideRight {
                .mainLink {
                    text-decoration: none;
                }
            }
        }

        .headingTxt {
            color: #954386;
            font-size: 18px;
        }
    }

    .headerForms {
        .datePicker {
            position: relative;

            .dateChapter {
                height: 40px !important;
                width: 100% !important;
            }

            .caledarImg {
                position: absolute;
                top: -10px;
                right: 10px;
                background-image: url(../../assets/images//calendar.svg);
                height: 60px;
                width: 60px;
                background-repeat: no-repeat;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-position: right center;
    padding-right: 4rem;
            }

            .p-calendar .p-inputtext {
                height: 40px !important;
                background-color: transparent !important;
                &::placeholder {
                    color: rgba(30, 10, 27, 0.3);
                    font-size: 14px;
                }
            }
        }

        // formSelect
        .form-select {
            height: 40px;
            border-radius: 12px;
            background-color: transparent !important;
    background-image:url(../images/color_down.png) !important;

       
            &:invalid {
                color: rgba(30, 10, 27, 0.3) !important;
                font-size: 14px;
            }

            &:focus {
                box-shadow: none !important;
                outline: none !important;
                border-color: rgba(30, 10, 27, 0.2) !important;

            }
        }

        .leftSide {
            .subBtn {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 40px;
                width: 40px;
                background: #ABC73E;
                border-radius: 10px;
                margin-left: auto;
            }
        }
    }

    // Tabs Section
    #tabsCashless {
        .p-tabview .p-tabview-nav {
            justify-content: space-between !important;
            background: transparent !important;
        }

        .p-tabview .p-tabview-nav li {
            width: 25%;
        }

        .p-tabview .p-tabview-panels {
            padding: 0px !important;
        }

        .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
            background: transparent !important;
            color: #954386 !important;
            font-weight: 600 !important;
        }

        .p-tabview .p-tabview-nav .p-tabview-ink-bar {
            background-color: #954386;
        }

        .p-unselectable-text .p-tabview-selected {
            background-color: red !important;
        }

        .p-tabview-nav-link {
            justify-content: center !important;
        }

        .p-tabview .p-tabview-nav li .p-tabview-nav-link {
            background: transparent !important;
            border-color: transparent transparent #abc73e !important;
            color:gray !important;
            font-weight: 400;
            border-width: 0 0 1px 0;
        }

        .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
            box-shadow: none !important;
        }

        .p-tabview .p-tabview-panels {
            background-color: transparent !important;
        }

        .tableBorders {
            // border: 1px solid rgba(30, 10, 27, 0.1);
            border-radius: 20px;
            overflow-x: auto;
            box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
                0px 3px 8px -1px rgba(50, 50, 71, 0.05);
            margin-top: 20px;
            background-color: #fff;
            table {
                min-width: 1050px;
            }

            .table {
                #shadowHead {
                    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
                        0px 3px 8px -1px rgba(50, 50, 71, 0.05) !important;

                    tr {
                        th {
                            background: rgba(254, 249, 251, 0.65);
                            border-bottom: 1px solid rgba(30, 10, 27, 0.1) !important;
                            opacity: 0.6;
                            font-weight: 400;
                            padding: 1rem;
                            font-size: 14px;
                            line-height: 18px;
                            color: rgba(30, 10, 27, 0.6);
                        }

                        .insideTd {
                            width: 18%;
                        }
                    }
                }

                .tableBody {
                    tr {
                        td {
                            // padding: 10px !important;
                            color: #1e0a1b;
                            font-weight: 500;
                            font-size: 14px;
                            padding: 1rem 1rem;
                            line-height: 20px;
                            border-bottom: none !important;

                            .StatusBlog {
                                background: rgba(235, 189, 24, 0.2);
                                border-radius: 10px;
                                padding: 3px 12px;

                                .warningTxt {
                                    color: #d7ac16;
                                    font-size: 12px;
                                    height: 5px;
                                    width: 100px;
                                }
                            }

                            .StatusBlogFailed {
                                background: rgba(249, 17, 17, 0.2);
                                border-radius: 10px;
                                padding: 3px 12px;

                                .failed {
                                    color: #F91111;
                                    font-size: 12px;
                                    height: 5px;
                                    width: 100px;
                                }
                            }

                            .StatusBlogSucc {
                                background: rgba(40, 193, 74, 0.2);
                                border-radius: 10px;
                                padding: 3px 12px;

                                .succTxt {
                                    color: #28c14a;
                                    font-size: 12px;
                                    height: 5px;
                                    width: 100px;
                                }
                            }

                            .hoverImg {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    #cahslessWalletBtn{
        .cx-btn-1{
           width: 170px;
        }
    }
}

.Recharge_Wallet {
    .card {
        background: #fff !important;
        border: none !important;
        box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
            0px 3px 8px -1px rgba(50, 50, 71, 0.05);
        border-radius: 16px !important;
        margin-bottom: 20px;

        .card-header {
            background: rgba(254, 249, 251, 0.65);
            border-bottom: none;
            box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
                0px 3px 8px -1px rgba(50, 50, 71, 0.05);
            padding: 18px;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;

            .headingTxt {
                color: #954386;
                font-size: 16px;
                font-weight: 600;
            }
        }

        .card-body {

            .keyTxt,
            .valTxt {
                font-size: 14px;
                color: #000;
            }
        }

        .card-footer {
            background: rgba(254, 249, 251, 0.65);
            border-top: none;
            box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
                0px 3px 8px -1px rgba(50, 50, 71, 0.05);
            padding: 15px;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;

            .footertxt {
                color: gray;
                font-size: 12px;
            }

            .keyTxt,
            .valTxt {
                color: #000;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    .belowBtns {
        display: flex;
        justify-content: flex-end;
    }

    .fieldSetCUST {
        position: relative;

        // Form-contriol text
        .formsForValid {
            height: 50px;
            border-radius: 10px;

            &::placeholder {
                color: rgba(30, 10, 27, 0.6);
                font-size: 14px;
            }

            &:focus {
                box-shadow: none !important;
                outline: none !important;
                border-color: rgba(30, 10, 27, 0.2) !important;
            }
        }
    }
}

.tableTxt{
    color: gray !important;
}
.option-one{
    color: red !important;
}
