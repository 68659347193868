body.dark-only .mydiary_wrapper {
  background-color: #111727;
}
body.dark-only .mydiary-list-wrapper {
  background-color: #202938;
}
body.dark-only .mydiary-detail-wrapper,
body.dark-only .send-msg-main,
body.dark-only #form_select,
body.dark-only .fieldSetCUST .p-calendar .p-inputtext {
  background-color: #202938;
}

body.dark-only .md-text-box {
  color: white;
}

body.dark-only .left-chat-section,
body.dark-only .right-chat-section {
  background-color: #111727;
}
body.dark-only .left-head {
  input {
    background-color: #202938;
    &::placeholder {
      color: gray;
    }
  }
}

body.dark-only .left {
  label {
    color: gray;
  }
}

body.dark-only .name,
body.dark-only .date,
body.dark-only .bottom-section {
  label {
    color: gray;
  }
}

body.dark-only .send-msg-main .msg-text {
  color: gray;
}

body.dark-only #ComposeMessage .MessageCompose {
  background-color: #111727;
}

body.dark-only .formsForValid,
body.dark-only #CreateAuthorisedPerson .CreateAuthInput .form-select,
body.dark-only .mydiary-list-heading,
body.dark-only .main-header .header-wrapper .left .dropdown .dropdown-menu,
body.dark-only .main-header .header-wrapper .profile .dropdown .dropdown-menu,
body.dark-only .customer-option .dropdown-menu,
// Yellow background
body.dark-only .Attendance_calender .calendar-outer-wrapper .calendar-inner-wrapper .legends .holidays-wrapper,
body.dark-only .Attendance_calender .calendar-outer-wrapper .calendar-inner-wrapper .legends .categories-wrapper,
body.dark-only .topic-accordion-wrapper .accordion-item:first-of-type .accordion-button,
body.dark-only .Assignment .review-main-wrapper .score-card,
body.dark-only .Assignment .review-main-wrapper .comment-card {
  background-color: #202938;
}

body.dark-only .cx-sidebar .menus-wrapper .activeMenu,
body.dark-only .my_kids .allKidsCard .customer-option .dropdown-item,
body.dark-only .fieldSetCUST .formsForTextArea {
  background-color: #202938 !important;
}

// body.dark-only .mydiary-list-heading p::after
//  {
//   background-color: gray;
// }

body.dark-only
  #ComposeMessage
  .MessageCompose
  .main_body
  .CreateAuthInput
  .legendHere,
body.dark-only #CreateAuthorisedPerson .CreateAuthInput .legendHere,
body.dark-only .fieldSetCUST .legendHere {
  background-color: #202938;
  color: gray;
}

body.dark-only
  #ComposeMessage
  .MessageCompose
  .main_body
  .CreateAuthInput
  textarea {
  background-color: #202938; /* Adjust the background color for dark mode */
  color: rgba(255, 255, 255, 0.8); /* Adjust the text color for dark mode */
}

// body.dark-only .Recharge_Wallet .card .card-header{
//   background-color: #202938 !important;
// }

body.dark-only #VideoConference  .single_Video,
  body.dark-only #NoticeBoard .single_card,  
 body.dark-only #NoticeDetail .main_wrapper,
 body.dark-only #Bustracking_main .BusTacking_Top_tabs .PickUP .RouteSummary .RouteSummary_main,
 body.dark-only #Bustracking_main .BusTacking_Top_tabs .PickUP .Mapsection .RouteSummary_main,
body.dark-only #Bustracking_main .BusTacking_Top_tabs .PickUP .RouteSummary .RouteSummary_main .RouteSummary_body .Track_Body .Route .step_main div, 
body.dark-only #Bustracking_main .BusTacking_Top_tabs .PickUP .pickUo_Details .TripDetails,
body.dark-only #Bustracking_main .BusTacking_Top_tabs .PickUP .pickUo_Details .StaffDetails,
body.dark-only #RenewTransportation .renewTransport_main .Renew_Card,
body.dark-only #TransactionsFlexy input,
body.dark-only #AuthoriswPersone .activity-table-wrapper tbody,
body.dark-only #ViewAuthorisedPerson .view_wrapper,
body.dark-only #CreateAuthorisedPerson .view_wrapper,
body.dark-only .NavLinkBackground ,
body.dark-only .mydiary-detail-wrapper .md-inner-wrapper .comment-box,
body.dark-only .Cashless_Wallet .cashlessCard,
body.dark-only .Cashless_Wallet #tabsCashless .tableBorders,

body.dark-only .Recharge_Wallet .card .card-footer,
body.dark-only .cx-sidebar .cx-sidebar-wrapper,
body.dark-only .cx-sidebar .menus-wrapper .accordion .accordion-item,
body.dark-only .my_kids .allKidsCard,
// body.dark-only .Edit_KidsDetails .mainLandingCard,
// body.dark-only .viewKids_details .mainLandingCard,
// body.dark-only .New_registration .mainLandingCard,
body.dark-only .transfer_certificate .perInfoCard,
body.dark-only .transfer_certificate .bigCard,
body.dark-only .Request_NewTc .perInfoCard,
body.dark-only .Request_NewTc .bigCard,
body.dark-only .Document .DocCard,
body.dark-only .Document .BorderedCard,
body.dark-only .Document .BorderedLabel,
body.dark-only .Certificate .mainLandingCard,
body.dark-only .Fees #innerSlideTab .tableBorders,
body.dark-only .Fees #TransactionsFlexy .tableBorders,
body.dark-only .Invoice_fee .mainLandingCard,
body.dark-only .Attendance_calender .calendar-outer-wrapper,
body.dark-only .Attendance_calender .calendar-inner-wrapper,
body.dark-only .filter-wrapper .fw-input-fields .p-inputtext,
body.dark-only .Attendance_calender .bottom-status-bar,
body.dark-only .Apply_leave .form-wrapper,
body.dark-only .curiculum-card-design .curiculum-inner-card,
body.dark-only .Topic_main .topic-main-wrapper .custom-body2::before,
body.dark-only .topic-accordion-wrapper .tc-lesson-header,
body.dark-only .Topic_main .topic-main-wrapper .custom-header,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .custom-header,
body.dark-only .topic-accordion-wrapper .accordion-body,
body.dark-only .Assignment .assignment-main-wrapper .p-accordion .p-accordion-header .p-accordion-header-link,
body.dark-only .Assignment .assignment-main-wrapper .p-accordion-content,
body.dark-only .Assignment .assignment-main-wrapper  .p-accordion
.p-accordion-header:not(.p-disabled).p-highlight
.p-accordion-header-link,
body.dark-only .Assignment .review-main-wrapper,
body.dark-only .description-box,
body.dark-only .Study_material_topic .study-material-topic-details .study-material-details-wrapper,
body.dark-only .Study_material_topic .study-material-topic-details .smt-bottom .smt-topic,
body.dark-only .Courses .course-card,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .course-topic-details,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .custom-body2::before,
body.dark-only .Id_cards .tableBorders,
body.dark-only .main-header .header-wrapper .right .bell .dropdown .dropdown-menu {
  background-color: #111727;
}

body.dark-only .Assignment .review-main-wrapper,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .study-material-details-wrapper,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .smt-bottom
  .smt-topic,
body.dark-only
  .AboutCourseDetails
  .course-topic-details-wrapper
  .course-topic-details {
  border: 2px solid #111727;
}

body.dark-only #cx-main {
  background: linear-gradient(180deg, #111727 0%, #111727 100%);
  // min-height: calc(100vh - 60px);
  // background-image: url(../images/images/general/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

body.dark-only .communication_icon {
  background-image: url(../images/comm.svg);
}

body.dark-only .NavLinkBackground,
body.dark-only .Modal_Header,
body.dark-only .Modal_Body,
body.dark-only .Modal_Footer,
body.dark-only .Recharge_Wallet .card,
body.dark-only .Attendance_calender .activity-table-wrapper table tbody,
body.dark-only .Topic_main .topic-main-wrapper .custom-body,
body.dark-only .Topic_main .topic-main-wrapper .custom-body2,
body.dark-only .Assignment .main-assignment-wrapper .assignment-card,
body.dark-only
  .Assignment
  .assignment-main-wrapper
  .p-accordion-header
  .p-accordion-header-link:hover,
body.dark-only .sm-card-wrapper .sm-top-card,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .custom-body,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .custom-body2 {
  background-color: #111727 !important;
}

body.dark-only #TransactionsFlexy input {
  // background-image: url(../images/white_search.png);
  background-image: url(../../assets/images/feesearch.svg);
}

body.dark-only #VideoConference .single_Video .heading,
body.dark-only #NoticeBoard .single_card .title,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .TripDetails
  .Details
  label,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .StaffDetails
  .Calling_card_single
  .Name
  label,
body.dark-only #RenewTransportation .renewTransport_main .Renew_Card .name,
body.dark-only
  #RenewTransportation
  .renewTransport_main
  .Renew_Card
  .bottom
  .label
  span,
body.dark-only .cx-sidebar .menu-outer-warpper .menu-name,
body.dark-only .main-header .header-wrapper .left .u-name .name,
body.dark-only .main-header .header-wrapper .profile .u-name .name,
body.dark-only .main-header .header-wrapper .right #profile .h-menu-item a,
body.dark-only .my_kids .allKidsCard .textProp .head,
body.dark-only .my_kids .allKidsCard .belowCardDet .cardTxt .ValLab,
body.dark-only
  .Attendance_calender
  .calendar-outer-wrapper
  .calendar-inner-wrapper
  .legends
  .holidays-wrapper
  .hw-title
  p,
body.dark-only
  .Attendance_calender
  .calendar-outer-wrapper
  .calendar-inner-wrapper
  .legends
  .categories-wrapper
  .cw-title
  p,
body.dark-only .curiculum-card-design .curiculum-inner-card a h4 {
  color: white;
}

body.dark-only .my_kids .allKidsCard .customer-option .dropdown-item a {
  color: white !important;
}

body.dark-only #text-secondary,
body.dark-only #ViewAuthorisedPerson .Card_imformation .single_information p,
body.dark-only .CreateAuthInput .form-select:invalid ,
body.dark-only .CreateAuthInput .formsForValid::placeholder ,
body.dark-only .mydiary-list-wrapper .mydiary-list .mydiary-single-item .msi-text-box .mt-heading p,
body.dark-only .mydiary-detail-wrapper .md-inner-wrapper .comment-box .sub-comment .commenting-person p,

body.dark-only .mydiary-detail-wrapper .md-inner-wrapper .comment-box .comment .commenting-person p,
body.dark-only .Cashless_Wallet #tabsCashless .tableBorders .table .tableBody tr td,
body.dark-only .Recharge_Wallet .card .card-body .valTxt,
body.dark-only .Recharge_Wallet .card .card-body .keyTxt,
body.dark-only .Recharge_Wallet .card .card-footer .keyTxt,
body.dark-only .Recharge_Wallet .card .card-footer .valTxt,
body.dark-only .Recharge_Wallet .card .fieldSetCUST .formsForValid::placeholder,
// body.dark-only .fieldSetCUST .formsForValid::placeholder
body.dark-only .transfer_certificate .perInfoCard .cardVal,
body.dark-only .labelBlackVal,
body.dark-only .transfer_certificate .bigCard .tableBorders .tableBody tr td,
body.dark-only .Request_NewTc .perInfoCard .cardVal,
body.dark-only .Document .DocCard .headingFlex .HeadTxt,
body.dark-only .Document .BorderedCard .headingFlex .HeadTxt,
body.dark-only .Document .BorderedCard .progress-bar1,
body.dark-only .Fees #innerSlideTab .searchflexy .searchPanel .form-control,
body.dark-only .Fees #innerSlideTab .tableBorders .table .tableBody tr td,
body.dark-only .Fees #TransactionsFlexy .tableBorders .table .tableBody tr td,
body.dark-only .invoice-outer-wrapper .invoice-inner-wrapper .invoice-bottom-table tbody .address-row  td:first-child .ib-left .payment-input-wrapper .pi-heading,
body.dark-only .invoice-outer-wrapper .invoice-inner-wrapper .invoice-bottom-table tbody .address-row  td:first-child .ib-left .payment-input-wrapper .pi-amount-wrapper .pi-amount,
body.dark-only #Fee_searchinput,
body.dark-only .Attendance_calender .calendar-outer-wrapper .calendar-inner-wrapper .legends .holidays-wrapper .hw-body p,
body.dark-only .Attendance_calender .calendar-outer-wrapper .calendar-inner-wrapper .legends .categories-wrapper .cw-body p,
body.dark-only .Attendance_calender .activity-table-wrapper table tbody tr td,
body.dark-only .curiculum-card-design .curiculum-inner-card a .topic-list,
body.dark-only .topic-accordion-wrapper .tch-left .topic-list1,
body.dark-only .Assignment .main-assignment-wrapper .assignment-card a .ac-heading,
body.dark-only .Assignment .main-assignment-wrapper .assignment-card a .ac-text-box-inner .ac-text-box .ac-text span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .subject span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .CreatedOn span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .submissinDate span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .assignedBy span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .status span,
body.dark-only .Assignment .assignment-details-wrapper .ad-text-box .ad-detail-text .submittedOn span,
body.dark-only .Assignment .assignment-main-wrapper .assignment-accordion-wrapper .assignment-accordion .question-wrapper .question-title,
body.dark-only .Assignment .assignment-main-wrapper .assignment-accordion-wrapper .assignment-accordion .subtitle,
body.dark-only .Assignment .review-main-wrapper .subtitle,
body.dark-only .Assignment .review-main-wrapper .comment-card p,
body.dark-only .Study_material_topic .study-material-topic-details .study-material-details-wrapper .ad-text-box .ad-detail-text .subject span,
body.dark-only .Study_material_topic .study-material-topic-details .study-material-details-wrapper .ad-text-box .CreatedOn span,

body.dark-only .Study_material_topic .study-material-topic-details .study-material-details-wrapper .ad-text-box .assignedBy span,
body.dark-only .Study_material_topic .study-material-topic-details .study-material-details-wrapper .ad-text-box .status span,
body.dark-only .Study_material_topic .study-material-topic-details .rating-count,
body.dark-only .Courses .main-courses-wrapper .course-card a .course-heading,
body.dark-only .Courses .main-courses-wrapper .course-card a .duration,
body.dark-only .sm-card-wrapper .sm-top-card .sm-heading,
body.dark-only .sm-card-wrapper .sm-top-card .sm-details,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .course-topic-details .cl-text-box .cl-duration,
body.dark-only .AboutCourseDetails .course-topic-details-wrapper .course-topic-details .cl-text-box .cl-name-rating,
body.dark-only .Id_cards .tableBorders .table .tableBody tr td {
  color: #dedede;
}
body.dark-only .fieldSetCUST .formsForValid::placeholder,
body.dark-only .fieldSetCUST .formsForTextArea::placeholder,
body.dark-only .labelValue,
body.dark-only .fieldSetCUST .form-select:invalid,
body.dark-only .fieldSetCUST .p-calendar .p-inputtext,
body.dark-only .fieldSetCUST .p-calendar .p-inputtext::placeholder,
body.dark-only #form_select,
body.dark-only .transfer_certificate .bigCard .belowTxtCard label,
body.dark-only .calendar-section,
body.dark-only .description-box .db-text,
body.dark-only .main-header .header-wrapper .right #bell .name,
body.dark-only .main-header .header-wrapper .right #bell .shift {
  color: #d1d1d1;
}

body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .StaffDetails
  .heading,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .TripDetails
  .heading,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .RouteSummary_main
  .top,
body.dark-only #ViewAuthorisedPerson .view_wrapper .Main_heading,
body.dark-only #CreateAuthorisedPerson .view_wrapper .Main_heading,
body.dark-only .mydiary-detail-wrapper .main-diary-title,
body.dark-only .mydiary-list-heading p::after,
body.dark-only
  .mydiary-detail-wrapper
  .md-inner-wrapper
  .comment-box
  .comment-heading
  p::after,
body.dark-only .modal-body .borderModal,
body.dark-only .modal-body .borderModalBelow,
body.dark-only .Edit_KidsDetails .editDetailHead,
body.dark-only .viewKids_details .kidsHeading,
body.dark-only .viewKids_details .generalTitle,
body.dark-only .breakLine,
body.dark-only .Certificate .certHeadAdj,
body.dark-only .invoice-heading-wrapper,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .particular-table
  tbody
  tr:nth-child(1),
body.dark-only .invoice_border,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .particular-table
  tbody
  tr:nth-child(8),
body.dark-only
  .Assignment
  .assignment-main-wrapper
  .assignment-accordion-wrapper
  .assignment-accordion
  .subtitle,
body.dark-only .description-box .db-heading {
  border-bottom: 1px solid gray;
}

body.dark-only
  .Fees
  #innerSlideTab
  .searchflexy
  .searchPanel
  .form-control:focus {
  border-color: white;
}

body.dark-only #VideoConference .single_Video .time,
body.dark-only #VideoConference .single_Video .Participant .heading_Participant,
body.dark-only #NoticeDetail .main_wrapper .body .qution,
body.dark-only #NoticeDetail .main_wrapper .body .answer,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .RouteSummary
  .RouteSummary_main
  .RouteSummary_body
  p,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .RouteSummary
  .RouteSummary_main
  .top
  .list_view,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .TripDetails
  .Details
  p,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .pickUo_Details
  .StaffDetails
  .Calling_card_single
  .Name
  p,
body.dark-only
  #Bustracking_main
  .BusTacking_Top_tabs
  .PickUP
  .Mapsection
  .RouteSummary_main
  .google-map
  .map-main
  .map-bottom-wrapper
  .map-location-icons
  p,
body.dark-only .switchCase .list_view,
body.dark-only
  #RenewTransportation
  .renewTransport_main
  .Renew_Card
  .bottom
  .label,
body.dark-only #TransactionsFlexy .searchPanel input::placeholder,
body.dark-only #AuthoriswPersone .activity-table-wrapper tbody td,
body.dark-only #ViewAuthorisedPerson .view_wrapper .Header .View_name,
body.dark-only
  #ViewAuthorisedPerson
  .Card_imformation
  .single_information
  label,
body.dark-only
  .mydiary-list-wrapper
  .mydiary-list
  .mydiary-single-item
  .msi-text-box
  .mt-details
  p,
body.dark-only
  .mydiary-detail-wrapper
  .md-inner-wrapper
  .main-diary-details-wrapper
  .time-stamp
  .time,
body.dark-only
  .mydiary-detail-wrapper
  .md-inner-wrapper
  .main-diary-details-wrapper
  .time-stamp
  .priority-details,
body.dark-only
  .mydiary-detail-wrapper
  .md-inner-wrapper
  .comment-box
  .sub-comment
  .commented
  p,
body.dark-only
  .mydiary-detail-wrapper
  .md-inner-wrapper
  .comment-box
  .comment
  .commented
  p,
body.dark-only .Cashless_Wallet .cashlessCard .differenceHold .insideLeft .hTxt,
body.dark-only
  .Cashless_Wallet
  .headerForms
  .datePicker
  .p-calendar
  .p-inputtext::placeholder,
body.dark-only .cx-sidebar .accordion-body .sub-menus ul li a,
body.dark-only .main-header .header-wrapper .left .u-name .shift,
body.dark-only .main-header .header-wrapper .profile .u-name .shift,
body.dark-only .my_kids .allKidsCard .belowCardDet .cardTxt .keyLab,
body.dark-only
  .viewKids_details
  .mainLandingCard
  .profileCard
  .userCard
  .UserName,
body.dark-only .labelKey,
body.dark-only .transfer_certificate .perInfoCard .cardKey,
body.dark-only .Request_NewTc .perInfoCard .cardKey,
body.dark-only .Document .DocCard .footertxt,
body.dark-only .Document .BorderedCard .pars,
body.dark-only .Certificate .certHeadAdj .leftCont .belowLab,
body.dark-only .Certificate .certHeadAdj .right .RightlAB,
body.dark-only
  .Fees
  #innerSlideTab
  .searchflexy
  .searchPanel
  .form-control::placeholder,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-head
  .address-row
  .address-table
  td:last-child,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-head
  .address-row
  .address-table2
  td:last-child,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .particular-table
  tbody
  tr
  td,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-bottom-table
  tbody
  .address-row
  td:first-child
  .ib-left
  .payment-input-wrapper
  .pi-amount-wrapper
  .pi-text,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-bottom-table
  tbody
  .address-row
  td:last-child
  .ib-right
  .charges
  p
  span,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-bottom-table
  tbody
  .address-row
  td:last-child
  .ib-right
  .amount-to-pay
  p
  span,
body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-bottom-table
  tbody
  .address-row
  td:first-child
  .ib-left
  .payment-input-wrapper
  .pi-note,
body.dark-only .filter-wrapper .fw-input-fields .p-inputtext::placeholder,
body.dark-only .Attendance_calender .bottom-status-bar .filter-result-count p,
body.dark-only .Attendance_calender .attendance-status .text-box p,
body.dark-only .curiculum-card-design .curiculum-inner-card a .topic-list span,
body.dark-only .curiculum-card-design .curiculum-inner-card a .pars,
body.dark-only .topic-accordion-wrapper .tch-left .topic-list1 span,
body.dark-only
  .Assignment
  .main-assignment-wrapper
  .assignment-card
  a
  .ac-text-box-inner
  .ac-text-box
  .ac-text,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .subject,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .CreatedOn,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .submissinDate,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .assignedBy,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .status,
body.dark-only
  .Assignment
  .assignment-details-wrapper
  .ad-text-box
  .ad-detail-text
  .submittedOn,
body.dark-only
  .Assignment
  .assignment-main-wrapper
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link::before,
body.dark-only
  .Assignment
  .assignment-main-wrapper
  .p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link::after,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .study-material-details-wrapper
  .ad-text-box
  .ad-detail-text
  .subject,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .study-material-details-wrapper
  .ad-text-box
  .CreatedOn,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .study-material-details-wrapper
  .ad-text-box
  .assignedBy,
body.dark-only
  .Study_material_topic
  .study-material-topic-details
  .study-material-details-wrapper
  .ad-text-box
  .status,
body.dark-only .Study_material_topic .study-material-topic-details .smd-rate p,
body.dark-only
  .Courses
  .main-courses-wrapper
  .course-card
  a
  .course-progress-box
  .topic-list,
body.dark-only
  .Courses
  .main-courses-wrapper
  .course-card
  a
  .course-progress-box
  .percentage,
body.dark-only .sm-card-wrapper .sm-top-card .sm-name-rating p,
body.dark-only
  .sm-card-wrapper
  .sm-top-card
  .sm-details-text-box
  .sm-details
  span {
  color: gray;
}
body.dark-only .Cashless_Wallet .headerForms .form-select:invalid
// body.dark-only .my_kids .kidsCardSec .p-tabview .p-tabview-nav li .p-tabview-nav-link
{
  color: gray !important ;
}

body.dark-only .Cashless_Wallet .headerForms .datePicker .caledarImg,
body.dark-only .fieldSetCUST .caledarImg {
  background-image: url(../../assets/images/calendar1.png);
}
body.dark-only .cx-sidebar .accordion-item button::after,
body.dark-only .filter-wrapper .fw-input-fields .innerSelectBox .calendarLogo,
body.dark-only .calendarLogo1 {
  background-image: url(../../assets/images/calendar1.png);
}

body.dark-only .bus_icon {
  background-image: url(../../assets/images/white_bus.svg);
}
body.dark-only .cashless_icon {
  background-image: url(../../assets/images/white_cash.svg);
}
body.dark-only .administrator_icon {
  background-image: url(../../assets/images/white_administrator.svg);
}
body.dark-only .academics_icon {
  background-image: url(../../assets/images/white_academics.svg);
}

body.dark-only .attendance_icon {
  background-image: url(../../assets/images/white_attendance.svg);
}
body.dark-only .dashboard_icon {
  background-image: url(../../assets/images/white_dashboard.svg);
}

body.dark-only .setting-icon {
  background-image: url(../../assets/images/white_setting.svg);
}

body.dark-only .bell_icon {
  background-image: url(../../assets/images/white_bell.svg);
}

body.dark-only
  .cx-sidebar
  .menus-wrapper
  .accordion
  .accordion-item
  button::after {
  background-image: url(../images/gray_right_arrow.svg);
}

body.dark-only .transfer_certificate .bigCard .tableBorders {
  box-shadow: 0px 0px 1px rgb(56, 56, 56),
    /* Adjust shadow color and opacity */ 0px 3px 8px -1px rgb(56, 56, 56); /* Adjust shadow color and opacity */
}

body.dark-only .Print {
  background-image: url(../../assets/images/print1.svg);
}

body.dark-only .save {
  background-image: url(../../assets/images/save2.svg);
}

body.dark-only .srchImg {
  background-image: url(../../assets/images/feesearch.svg);
}

body.dark-only .hoverImg {
  background-image: url(../../assets/images/tabsearch1.svg);
}

body.dark-only
  .invoice-outer-wrapper
  .invoice-inner-wrapper
  .invoice-bottom-table
  tbody
  .address-row
  td:first-child
  .ib-left
  .payment-input-wrapper
  .pi-amount-wrapper
  .pi-amount {
  border: 1px solid gray;
}

body.dark-only .edit_icon {
  background-image: url(../../assets/images/white_edit.svg);
}

body.dark-only .delete_icon {
  background-image: url(../../assets/images/white_delete.svg);
}

body.dark-only
  .topic-accordion-wrapper
  .accordion-item:first-of-type
  .accordion-button {
  border: 2px solid #111727;
}

body.dark-only .Gray_Like_Btn,
body.dark-only .like-btn1 {
  // position: absolute;

  background-image: url(../images/white_like.svg);
}

body.dark-only .Cashless_Wallet .headerForms .form-select,
body.dark-only .fieldSetCUST .form-select {
  background-image: url(../images/white_down.png) !important;
}

body.dark-only .close {
  background-image: url(../images/white_cancel.png);
}
