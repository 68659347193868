.Notification_text{
    color: #241549;
    font-size: 15px;
    font-weight: 100;
    margin-left: 5px;
}
.Notification_dateTime{
    color:#857E84;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 45px;
    font-size: 13px;


}
.notification_image{
    background-color:#EDDFE1;
    border-radius: 100%;
    padding: 10px; 
    height: 40px;


}


.Notification_header{
    background-color:#FEF9FBA6 ;
 
    box-shadow:1px 1px 10px 1px #79266A12; 
    padding: 1rem; 
    margin-bottom:1rem; 
    border-radius:12px; 
    border: 2px solid #79266A24;




   
}

body.dark-only .Notification_header{
    background-color: #111727;
    border: 2px solid #111727;


  
   
}

body.dark-only .Notificatio_heading{
    color: white;
}


body.dark-only  .Notification_text{
    color :#dedede;
}

