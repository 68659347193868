.todocardheadesstyle{
    background-color: #FEF9FBA6;
    // border-left: 5px solid #ADC927 ;
    box-shadow:1px 1px 10px 1px rgba(0.1, 0.1, 0.1, 0.1); /* Add shadow */
    padding: 1rem; /* Adjust padding as needed */
    margin-bottom:1rem; 
    border-radius:12px; 
   
}
body.dark-only .todocardheadesstyle{
    background-color: #111727;
  
   
}





.Name_Style{
color: #000000;
    font-size: 14px;
    margin-top:5px;
    margin-left: 5px;
}


body.dark-only .Feed_heading,
body.dark-only .Name_Style,
body.dark-only .text_Style{
    color: white
}


.Time_Style{
    font-size: 10px;
    margin-left: 5px;


}

.text-view{
    margin-top: 10px;
}
.text_Style{
    font-size: 14px;
}

.text1{
    font-size: 16px;
    color: #ABC73E;
    // margin-top: 10;
    margin-left: 5px;
    margin-right: 5px;

}

.text2{
    font-size: 16px;
    color: #ABC73E;
    margin-left: 5px;


    // margin-top: 10;




}


.text_Style{
    font-size: 16px;
    color: #000000;
}
.text_Style1{
    font-size: 12px;
    color: #857E84;
}

.Gray_Like_Btn{
    // position: absolute;


    background-image: url(../images/like_btn.svg);
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    padding-right: 3.5rem;
    margin-right: -34px;
    margin-top: 10px;
}





